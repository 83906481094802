import React, { FC, useCallback, useEffect, useState } from "react";
import { RelevantServicesProps, TopServices } from "./top-services";
import { useWebgate } from "@xvia/webgate-connect";
import { Catalog } from "../../apis/search-catalog";
import { getServerData } from "../../util/get-server-data";
import { getTopCatalog } from "../../apis/relevant-catalog";
import { useHistory } from "react-router-dom";

export const TopServicesContainer: FC = () => {
  const { accessToken, serverData } = useWebgate();
  let history = useHistory();
  const [relevantCatalogList, setRelevantCatalogList] = useState<Catalog[]>(
    serverData?.top || getServerData("top", null)
  );
  const getServerRelevant = useCallback(() => {
    console.log('4 TopServicesContainer INICIO');
    getTopCatalog(accessToken)
      .then((result)=>{
        setRelevantCatalogList(result);
        console.log('4 TopServicesContainer FIM');
      })
      .catch((err) => {
        console.log(err);
        console.log('4 TopServicesContainer ERRO');
      });
  }, [accessToken]);

  const onClickCard = useCallback(
    (categorySlug, slug) => {
      history.push(`${categorySlug}/${slug}`);
    },
    [history]
  );

  useEffect(() => {
    if (!relevantCatalogList?.length) getServerRelevant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const relevantServicesProps: RelevantServicesProps = {
    services: relevantCatalogList?.map((catalog) => {
      return {
        title: catalog.title,
        categoryIcon: catalog.icon,
        onClick: () => {
          onClickCard(catalog.categorySlug, catalog.slug);
        },
        imageUrl: catalog.imageUrl,
        onClickFavourite: () => {},
        type: catalog.isOnline ? "online" : "in-person",
        targets: catalog?.targets,
      };
    }),
  };

  return <TopServices {...relevantServicesProps} />;
};
