import React, { FC, MouseEventHandler } from "react";
import { Title } from "../typography/title";
import { Col, Row, Space } from "antd";
import { Rating } from "../rating/rating";
import { Info } from "../info/info";
import { Button } from "../button/button";

export interface CatalogCardProps {
  title?: string;
  rating?: number;
  totalRatings?: number;
  isFree?: boolean;
  isOnline?: boolean;
  lastUpdate?: string;
  link?: string;
  onFavoriteClick?: MouseEventHandler;
  onPdfClick?: MouseEventHandler;
  onServiceClick?: MouseEventHandler;
  onRatingClick?: (rating: number) => void;
}

export const CatalogCard: FC<CatalogCardProps> = (
  {
    title,
    rating = 0,
    totalRatings = 0,
    isFree,
    isOnline,
    link,
    lastUpdate,
    onFavoriteClick,
    onPdfClick,
    onServiceClick,
    onRatingClick,
  },
  size: number = 10
) => {
  // const totalRatingsText = useMemo(
  //   () =>
  //     `${totalRatings.toLocaleString()} ${
  //       totalRatings === 1 ? "avaliação" : "avaliações"
  //     }`,
  //   [totalRatings]
  // );

  return (
    <div className={"xvia-catalog-card"}>
      <Row gutter={30} align="middle">
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Space size={0} direction={"vertical"}>
            <Title level={4}>{title}</Title>
            <div className={"xvia-catalog-card__info_content"}>
              <span>
                <Rating
                  value={rating}
                  readOnly={false}
                  onClickItem={onRatingClick}
                  aria-label={`Avaliação de ${rating} estrelas em ${totalRatings} avaliações`}
                />
              </span>
              {!!lastUpdate && (
                <Info text={`Atualizado há ${lastUpdate}`} icon={"fa-clock"} aria-label={`Última atualização: ${lastUpdate}`} />
              )}
            </div>
          </Space>
        </Col>
        <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Row align="middle">
            <Col span={10}>
              <Space direction={"vertical"}>
                {/*{isFree && <Info text={"Gratuito"} icon={"fa-check"} />}*/}
                {/*{!isFree && (*/}
                {/*  <Info text={"Tem custo"} icon={"fa-circle-dollar"} />*/}
                {/*)}*/}
                {isOnline && (
                  <Info text={"Online"} icon={"fa-earth-americas"} aria-label="Serviço disponível online" />
                )}
              </Space>
            </Col>
            <Col span={14}>
              <Space size={6}>
                {/*<Button*/}
                {/*  title={""}*/}
                {/*  icon={"fa-heart"}*/}
                {/*  circle={true}*/}
                {/*  onClick={onFavoriteClick}*/}
                {/*/>*/}
                <Button
                  title={""}
                  icon={"fa-file-pdf"}
                  circle={true}
                  onClick={onPdfClick}
                  aria-label="Download do PDF"
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
          {link && (
            <Button
              title={"Acessar Serviço Digital"}
              icon={"fa-solid fa-right-to-bracket"}
              onClick={onServiceClick}
              aria-label="Acessar Serviço Digital"
            />
          )}
          {!link && (
            <Info
              text={"Serviço realizado presencialmente"}
              icon={"fa-location-dot"}
              aria-label="Serviço disponível presencialmente"
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
