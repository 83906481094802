import React, { FC } from "react";
import {
  ServiceCard,
  ServiceCardProps,
} from "../../components/cards/service-card";
import { Spin } from "antd";
import { Container } from "../../components/container/container";
import { Carrocel } from "../../components/carousel/carrocel";
import { BreakPage } from "../../components/break-page/break-page";

export interface RelevantServicesProps {
  services?: ServiceCardProps[];
}

export const RelevantServices: FC<RelevantServicesProps> = ({ services }) => {
  return (
    <div className="xvia-relevant-services">
      <Container className="xvia-relevant-services__container">
        <BreakPage text={"SERVIÇOS EM DESTAQUE"} color={"#0661A7"} />
        <Carrocel>
          {!!services &&
            services?.map((service) => {
              return (
                <div 
                key={service.title}
                role="button"
                tabIndex={0}
                aria-label={`Acessar o serviço ${service.title}`}
                >
                  <ServiceCard
                    title={service.title}
                    categoryIcon={service?.categoryIcon}
                    onClick={service.onClick}
                    onClickFavourite={service.onClickFavourite}
                    rating={service?.rating}
                    type={service?.type}
                    imageUrl={service?.imageUrl}
                    targets={service?.targets}
                  />
                </div>
              );
            })}
        </Carrocel>
        {!services && (
          <div className={"xvia-relevant-services__loading"} aria-live="polite">
            <Spin />
          </div>
        )}
      </Container>
    </div>
  );
};
