import React, { useMemo } from "react";
import "./App.css";
import { Switch, Route, useLocation } from "react-router-dom";
import { Routes } from "./routes";
import { usePageTrackingGA } from "@xvia/webgate-connect";

function App() {
  const location = useLocation();

  usePageTrackingGA(location);

  const routes = useMemo(
    () =>
      Object.keys(Routes).map((path) => {
        const RouteComponent = Routes[path];
        return (
          <Route key={path} path={path}>
            <RouteComponent />
          </Route>
        );
      }),
    []
  );
  return <Switch>{routes}</Switch>;
}

export default App;
