import React, { FC, useCallback, useEffect, useState } from "react";
import { News, NewsProps } from "./news";
//import { getNews } from "../../apis/news-rss";
import { useWebgate } from "@xvia/webgate-connect";
import { getServerData } from "../../util/get-server-data";

const publicUrl = process.env.PUBLIC_URL || "";

export const NewsContainer: FC = () => {
  const { serverData } = useWebgate();
  const [newsList, setNewsList] = useState<any[]>(
    serverData?.news || getServerData("news", null)
  );
  const getServerNews = useCallback(() => {
    console.log('5 NewsContainer INICIO');
    /*getNews()
    .then((result)=>{
        setNewsList(result);
        console.log('5 NewsContainer FIM');
      })
      .catch((err) => {
        console.log(err);
        console.log('5 NewsContainer ERRO');
      });*/
  }, []);
  const onClickCard = useCallback((link) => {
    window.open(link, "_blank");
  }, []);

  const openLink = useCallback(() => {
    window.open("https://www.secom.mt.gov.br/inicio", "_blank");
  }, []);

  useEffect(() => {
    if (!newsList?.length) getServerNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getServerNews]);

  const newsProps: NewsProps = {
    news: newsList?.map((catalog) => {
      const options: any = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const today = new Date(catalog.pubDate);
      return {
        title: catalog.title,
        categoryName: catalog.categories,
        date: today.toLocaleDateString("pt-BR", options),
        onClick: () => {
          onClickCard(catalog.guid);
        },
        imageUrl: catalog?.enclosure?.url || `${publicUrl}/noimage.png`,
      };
    }),
    clickMore: openLink,
  };

  return <News {...newsProps} />;
};
