import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface LinkListProps {
  title: string;
  linkList: { text: string; link: string }[];
}

export const LinkList: FC<LinkListProps> = ({ title, linkList }) => {
  return (
    <nav aria-label={`Links de navegação para ${title}`} className={"xvia-link-list"}>
      <span className="xvia-link-list__title">{title}</span>
      <div>
        <ul className={"xvia-link-list__ul"}>
          {linkList.map((link) => {
            const ariaLabel = `${link.text} - Abre em uma nova aba`;
            return (
              <li key={link.link}>
                <a
                  href={link.link}
                  target={"_blank"}
                  className={"xvia-link-list__text"}
                  rel="noreferrer"
                  aria-label={ariaLabel}
                >
                  <FontAwesomeIcon
                    className={"xvia-link-list__icon"}
                    icon={`fa-solid fa-angle-right` as IconProp}
                  />
                  {link.text}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};
