import { FC, createElement, useCallback, useEffect, useState } from "react";
import { useWebgate } from "@xvia/webgate-connect";
import { getServerData } from "../../util/get-server-data";
import { useHistory } from "react-router-dom";
import { getDepartments, IDepartment } from "../../apis/department";
import { DepartmentList, DepartmentProps } from "./department-list";
import { BreadcrumbItem } from "../../components/breadcrumb/breadcrumb";

export const DepartmentListContainer: FC = () => {
  const { serverData } = useWebgate();
  let history = useHistory();

  const [departmentList, setDepartmentList] = useState<IDepartment[]>(
    serverData?.departmentList || getServerData("departmentList", null)
  );

  const [departmentListFiltred, setDepartmentListFiltred] =
    useState<IDepartment[]>(departmentList);

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  const onClickCard = useCallback(
    (slug) => {
      history.push(`/orgao/${slug}`);
    },
    [history]
  );

  const onClickSearch = useCallback(
    (filter) => {
      const filterd = departmentList?.filter((d) => {
        return (
          d.shortName?.toUpperCase()?.indexOf(filter?.toUpperCase()) !== -1
        );
      });
      setDepartmentListFiltred(filterd);
    },
    [departmentList]
  );

  useEffect(() => {
    if (!departmentList) {
      getDepartments()
        .then((result) => {
          setDepartmentList(result);
          setDepartmentListFiltred(result);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }

    setBreadcrumbs([{ name: "Órgãos" }]);

    return () => {
      setBreadcrumbs([]);
    };
  }, [departmentList]);

  const newsProps: DepartmentProps = {
    onClick: onClickCard,
    departmentList: departmentListFiltred,
    onClickSearch,
    breadcrumbs,
  };

  return createElement(DepartmentList, newsProps);
};
