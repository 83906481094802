import React, { FC } from "react";
import classNames from "classnames";

export interface TitleProps {
  level: 1 | 2 | 3 | 4 | 5;
  noMargin?: boolean;
  className?: string;
  id?: string;
}

export const Title: FC<TitleProps> = ({
  level,
  className,
  id,
  children,
  ...props
}) => {
  const titleClassName = classNames({
    "xvia-title-h": true,
    [`xvia-title-h${level}`]: true,
    [`xvia-title--no-margin`]: true,
    [className || ""]: !!className,
  });

  return React.createElement(
    `h${level}`,
    { className: titleClassName, ...props },
    children
  );
};
