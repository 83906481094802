import React, { FC, MouseEventHandler } from "react";
import classNames from "classnames";
// import { Title } from "../typography/title";

export interface NewsCardProps {
  title: string;
  categoryName: string;
  imageUrl: string;
  date: Date | string;
  onClick: MouseEventHandler;
}

export const NewsCard: FC<NewsCardProps> = ({
  title,
  categoryName,
  date,
  imageUrl,
  onClick,
}) => {
  const containerClassName = classNames({
    "xvia-news-card": true,
  });

  return (
    <button aria-label={`Notícia: ${title}, Categoria: ${categoryName}, Data: ${new Date(date).toLocaleDateString()}`} onClick={onClick} className={containerClassName}>
      <div
        className="xvia-news-card__thumb"
        style={{
          backgroundImage: `url("${imageUrl}")`,
        }}
        aria-label={`Imagem relacionada à notícia: ${title}`}
      />
      <div className="xvia-news-card__content">{title}</div>
    </button>
  );
};
