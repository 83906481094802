import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getServerData } from "../../util/get-server-data";
import { useWebgate } from "@xvia/webgate-connect";
import { Category, CategoryProps } from "./category";
import { getCategorys, ICategory } from "../../apis/categorys";

export const CategoryContainer: FC = () => {
  const { serverData } = useWebgate();
  let history = useHistory();

  const [categoryList, setCategoryList] = useState<ICategory[]>(
    serverData?.categoryList || getServerData("categoryList", null)
  );
  const getCategory = useCallback(() => {
    console.log('6 CategoryContainer INICIO');
    getCategorys()
    .then((result)=>{
        setCategoryList(result);
        console.log('6 CategoryContainer FIM');
      })
      .catch((err) => {
        console.log(err);
        console.log('6 CategoryContainer ERRO');
      });
  }, []);

  const onClickCategory = useCallback(
    (categorySlug) => {
      console.log(categorySlug);
      history.push(`/list/${categorySlug}`);
    },
    [history]
  );

  useEffect(() => {
    if (!categoryList?.length) getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryList]);

  const props: CategoryProps = {
    categoryList: categoryList?.map((category) => {
      return {
        icon: category.icon,
        detail: category.description,
        onClick: () => {
          onClickCategory(category.slug);
        },
      };
    }),
  };

  return <Category {...props} />;
};
