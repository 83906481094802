import React, { FC } from "react";
import classNames from "classnames";
import { Title } from "../typography/title";

export interface SidebarCardProps {
  title?: string;
  icon?: string;
  className?: string;
}

export const SidebarCard: FC<SidebarCardProps> = ({ title, className = "", children }) => {
  const containerClassName = classNames({
    "xvia-card": true,
    [className]: !!className,
  });

  const titleId = title ? `sidebar-card-title-${title.replace(/\s+/g, '-')}` : undefined;

  return (
    <div className={containerClassName} aria-labelledby={titleId}>
      {title && (
        <Title id={titleId} level={4} className={"xvia-card__title"}>
          <span aria-hidden="true">{"//"}</span> {title}
        </Title>
      )}
      {children}
    </div>
  );
};
