import React, { FC } from "react";
import {
  ServiceCard,
  ServiceCardProps,
} from "../../components/cards/service-card";

import { Button, Col, Row } from "antd";
import { Container } from "../../components/container/container";
import { BreakPage } from "../../components/break-page/break-page";

export interface RecommendedServicesProps {
  services?: ServiceCardProps[];
  clickMore?: () => any;
}

export const RecommendedServices: FC<RecommendedServicesProps> = ({
  services,
  clickMore,
}) => {
  return (
    <div className="xvia-recommended-services">
      <Container className="xvia-recommended-services__container">
        <BreakPage text={"SERVIÇOS PARA VOCÊ"} color={"#093089"} />

        <div>
          <Row justify="center" gutter={[16, 34]}>
            {!!services &&
              services?.map((service) => {
                return (
                  <Col
                    className="xvia-recommended-services__card"
                    xs={24}
                    sm={11}
                    md={11}
                    lg={6}
                    xl={6}
                    xxl={6}
                    tabIndex={0}
                    aria-label={`Acessar o serviço ${service.title}`}
                  >
                    <ServiceCard
                      title={service.title}
                      categoryIcon={service?.categoryIcon}
                      onClick={service.onClick}
                      onClickFavourite={service.onClickFavourite}
                      rating={service?.rating}
                      type={service?.type}
                      imageUrl={service?.imageUrl}
                      targets={service?.targets}
                    />
                  </Col>
                );
              })}
          </Row>
          <Button onClick={clickMore} style={{ marginTop: 20 }} aria-label="Ver todos os serviços recomendados">
            Ver todos
          </Button>
        </div>
      </Container>
    </div>
  );
};
