import React, { FC } from "react";
//@ts-ignore
import { Element } from "react-scroll";
import { HomeSearchContainer } from "../modules/home-search/home-search.container";
import { RecommendedServicesContainer } from "../modules/recommended-services/recommended-services.container";
import { RelevantServicesContainer } from "../modules/relevant-services/relevant-services.container";
import { NewsContainer } from "../modules/news/news.container";
import { AppsContainer } from "../modules/apps/apps.container";
import { CategoryContainer } from "../modules/category/category.container";
import { LinkBarContainer } from "../modules/link-bar/link-bar.container";
import { FooterContainer } from "../modules/footer/footer.container";
import { IndicatorsContainer } from "../modules/indicators/indicators.container";
import { OmbudsmanContainer } from "../modules/ombudsman/ombudsman.container";
import { TopServicesContainer } from "../modules/top-services/top-services.container";

export const Home: FC = () => {
  return (
    <>
      <Element name="search">
        <HomeSearchContainer />
      </Element>

      <LinkBarContainer />

      <Element name="recommendedServices">
        <RecommendedServicesContainer />
      </Element>

      <Element name="relevantServices">
        <RelevantServicesContainer />
      </Element>

      <Element name="topServices">
        <TopServicesContainer />
      </Element>

      <Element name="newsContainer">
        <NewsContainer />
      </Element>

      {/*<Element name="appsContainer">
        <AppsContainer />
      </Element>*/}

      <Element name="category">
        <CategoryContainer />
      </Element>

      <Element name="ombudsman">
        <OmbudsmanContainer />
      </Element>

      <Element name="indicators">
        <IndicatorsContainer />
      </Element>

      <iframe
        src="https://iframexvia.evagpt.com.br/#/iframe/VTJGc2RHVmtYMThEZi9PUXpXQmRwKzFBeW9oSUIvUFhBTzdlMHpJTWU1MVdBZENBd2FUbXhsU2xkLzRwaFZmSw=="
        id="childFrame"
        style={{
          zIndex: 999999,
          display: "block",
          position: "fixed",
          bottom: "0.625rem",
          right: "0.625rem",
          border: "none",
          width: "0.625rem",
          height: "0.625rem",
        }}
      ></iframe>ß

      <FooterContainer />
    </>
  );
};
