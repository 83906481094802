import React, {
  ChangeEventHandler,
  DetailedHTMLProps,
  FC,
  useCallback,
} from "react";
import classNames from "classnames";

export interface Options {
  value: any;
  text: string;
  placeholder?: string;
}

export interface SelectProps
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  onChange: (value: any) => void;
  items?: Options[];
}

export const Select: FC<SelectProps> = ({
  className,
  value,
  onChange,
  disabled,
  items,
  placeholder,
  ...props
}) => {
  const inputClassName = classNames({
    "xvia-form-input-select": true,
    "xvia-form-input--disabled": disabled,
    [className as string]: !!className,
  });

  const textChangeHandler = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    (event) => {
      const value = event.target.value;
      if (onChange) {
        onChange(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <select
      value={value}
      disabled={disabled}
      className={inputClassName}
      onChange={textChangeHandler}
      {...props}
    >
      {placeholder && (
        <option value="" disabled selected>
          {placeholder}
        </option>
      )}

      {items?.map((i) => {
        return (
          <option value={i.value} key={i.value}>
            {i.text}
          </option>
        );
      })}
    </select>
  );
};
