import React, { FC, MouseEventHandler } from "react";
import { SearchInput } from "../../components/search-input/search-input";
import { Container } from "../../components/container/container";
import { Row, Space } from "antd";

export interface HomeSearchProps {
  value?: string;
  imageBanner?: string;
  onClickSearch: MouseEventHandler;
  onChangeText: (text: string) => void;
  onClickCategory: (text: string) => void;
  links?: {
    text: string;
    href: string;
  }[];
}
export const HomeSearch: FC<HomeSearchProps> = ({
  onChangeText,
  onClickSearch,
  onClickCategory,
  links,
  value,
  imageBanner,
}) => {
  return (
    <div
      className="xvia-home-search"
      style={{
        backgroundImage: `url("${imageBanner}")`,
      }}
      aria-label="Área de busca de serviços"
    >
      <Container className="xvia-home-search__container">
        <SearchInput
          className="xvia-home-search__input"
          placeholder="Qual serviço você procura?"
          onClickSearch={onClickSearch}
          onChangeText={onChangeText}
          value={value}
          aria-label="Campo de busca de serviços"
        />
        {!!links?.length ? (
          <Row align="middle" justify="space-between">
            <Space className="xvia-home-search__links">
              <strong>Populares: </strong>
              {links.map(({ text, href }) => (
                <span
                  key={href}
                  onClick={() => {
                    onClickCategory(href);
                  }}
                  tabIndex={0}
                  aria-label={`Acessar o serviço popular: ${text}`}
                >
                  {text},
                </span>
              ))}
            </Space>
          </Row>
        ) : null}
      </Container>
      <div className={"xvia-home-search__gradient"} />
    </div>
  );
};
