import React, { FC, useCallback, useEffect, useState } from "react";
import {
  RecommendedServices,
  RecommendedServicesProps,
} from "./recommended-services";
import { useWebgate } from "@xvia/webgate-connect";
import { Catalog } from "../../apis/search-catalog";
import { getRecommendations } from "../../apis/recommendations-catalog";
import { getServerData } from "../../util/get-server-data";
import { useHistory } from "react-router-dom";

export const RecommendedServicesContainer: FC = () => {
  const { accessToken, serverData, portalConnect } = useWebgate();
  const [recommendedCatalogList, setRecommendedCatalogList] = useState<
    Catalog[]
  >(serverData?.recommendation || getServerData("recommendation", null));
  let history = useHistory();
  const getServerRecommended = useCallback(() => {
    console.log('1 RecommendedServicesContainer INICIO');
    getRecommendations(accessToken)
      .then((result)=>{
        setRecommendedCatalogList(result);
        console.log('1 RecommendedServicesContainer FIM');
      })
      .catch((err) => {
        console.log(err);
        console.log('1 RecommendedServicesContainer ERRO');
      });
  }, [accessToken]);
  const onClickCard = useCallback(
    (categorySlug, slug) => {
      history.push(`${categorySlug}/${slug}`);
    },
    [history]
  );

  useEffect(() => {
    if (!recommendedCatalogList?.length && !!accessToken)
      getServerRecommended();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const clickMore = useCallback(() => {
    portalConnect?.redirectTo("/app/dashboard");
  }, [portalConnect]);

  const recommendedServicesProps: RecommendedServicesProps = {
    services: recommendedCatalogList?.map((catalog) => {
      return {
        title: catalog.title,
        categoryIcon: catalog.icon,
        onClick: () => {
          onClickCard(catalog.categorySlug, catalog.slug);
        },
        onClickFavourite: () => {},
        type: catalog.isOnline ? "online" : "in-person",
      };
    }),
    clickMore,
  };

  if (!recommendedCatalogList?.length) {
    return <></>;
  }

  return <RecommendedServices {...recommendedServicesProps} />;
};
