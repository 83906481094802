import React, { FC, useCallback } from "react";
import { Ombudsman, OmbudsmanProps } from "./ombudsman";

export const OmbudsmanContainer: FC = () => {
  const openLink = useCallback((link) => {
    window.open(link, "_blank");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props: OmbudsmanProps = {
    itens: [
      {
        text: "Acesso à informação",
        icon: "fa-circle-info",
        detail: "Solicite acesso a informações públicas",
        onClick: () => {
          openLink("https://www.cge.mt.gov.br/acesso-a-informacao");
        },
      },
      {
        text: "Fale Cidadão",
        icon: "fa-message",
        detail: "Solicite acesso a informações públicas",
        onClick: () => {
          openLink("https://ouvidoria.controladoria.mt.gov.br/falecidadao/");
        },
      },
      {
        text: "Simplifica MT",
        icon: "fa-bars-staggered",
        detail: "Solicite acesso a informações públicas",
        onClick: () => {
          openLink(
            "https://ouvidoria.controladoria.mt.gov.br/falecidadao/servlet/cadastrardemanda?2"
          );
        },
      },
      {
        text: "Dados abertos",
        icon: "fa-database",
        detail: "Solicite acesso a informações públicas",
        onClick: () => {
          openLink("http://www.transparencia.mt.gov.br/downloads-de-bases");
        },
      },
    ],
  };

  return <Ombudsman {...props} />;
};
