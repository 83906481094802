import {
  FC,
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { List, ListProps } from "./list";
import { useWebgate } from "@xvia/webgate-connect";
import { getServerData } from "../../util/get-server-data";
import { Catalog, search } from "../../apis/search-catalog";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ICategory, getCategorys } from "../../apis/categorys";
import { IDepartment, getDepartments } from "../../apis/department";
import { BreadcrumbItem } from "../../components/breadcrumb/breadcrumb";

const striptags = require("striptags");

export const ListContainer: FC = () => {
  const { accessToken, serverData, portalConnect } = useWebgate();
  let history = useHistory();
  const location = useLocation();

  let { category, query, orgao } =
    useParams<{ category: string; query: string; orgao: string }>();

  const [categoryList, setCategoryList] = useState<ICategory[]>(
    serverData?.categoryList || getServerData("categoryList", null)
  );
  const [departmentList, setDepartmentList] = useState<IDepartment[]>(
    serverData?.departmentList || getServerData("departmentList", null)
  );
  const [catalogList, setCatalogList] = useState<any[]>(
    serverData?.catalogList || getServerData("catalogList", null)
  );
  const [searchTextFilter, setSearchTextFilter] = useState<string>(query);
  const [categorySlug, setCategorySlug] = useState<string>(category);
  const [disposition, setDisposition] = useState<"line" | "card">("card");
  const [page, setPage] = useState<number>(0);
  const [resultTotal, setResultTotal] = useState<number>();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  const currentPage = useMemo(() => page + 1, [page]);
  const currentDepartment = useMemo(
    () => departmentList?.find(({ slug }) => slug === orgao),
    [departmentList, orgao]
  );
  const currentCategory = useMemo(
    () => categoryList?.find(({ slug }) => slug === category),
    [category, categoryList]
  );

  const serverSearch = useCallback(() => {
    search(accessToken, query, category, orgao, page * 10)
      .then((res) => {
        setCatalogList(res);
        setResultTotal(res[0]?.resultTotal);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, category, orgao, page, resultTotal]);

  const getCategoryServer = useCallback(() => {
    getCategorys()
      .then(setCategoryList)
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, searchTextFilter]);

  const getDepartmentList = useCallback(() => {
    getDepartments()
      .then(setDepartmentList)
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, searchTextFilter]);

  const openLink = useCallback((link) => {
    if (link.indexOf("/app/") !== -1) {
      portalConnect?.redirectTo(link);
    } else {
      window.open(link, "_blank");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickCard = useCallback(
    (catalog: Catalog) => {
      const { categorySlug, slug } = catalog;

      history.push(`/${categorySlug}/${slug}`);
    },
    [history]
  );

  const onClickSearch = useCallback(() => {
    if (categorySlug)
      return history.push(`/list/${categorySlug}/${searchTextFilter || ""}`);

    history.push(`/search/${searchTextFilter}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTextFilter, history, categorySlug, category, query]);

  const onDepartmentClick = useCallback((departamentSlug: string) => {
    history.push(`/list/orgao/${departamentSlug}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPaginate = useCallback((id: number) => {
    setPage(id - 1);
    if (typeof window !== "undefined" && window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    serverSearch();
    if (!categoryList) getCategoryServer();
    if (!departmentList) getDepartmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, category, orgao, page]);

  useEffect(() => {
    if (location.pathname.includes("search")) {
      return setBreadcrumbs([{ name: query }]);
    }
    if (orgao && !!currentDepartment) {
      return setBreadcrumbs([{ name: currentDepartment.name }]);
    }
    if (query && !!currentCategory) {
      return setBreadcrumbs([
        { name: currentCategory.name, path: `/list/${currentCategory.slug}` },
        { name: query },
      ]);
    }
    if (category && !!currentCategory) {
      return setBreadcrumbs([{ name: currentCategory.name }]);
    }

    setBreadcrumbs([{ name: "Serviços" }]);

    return () => {
      setBreadcrumbs([]);
    };
  }, [
    category,
    currentCategory,
    currentDepartment,
    location.pathname,
    orgao,
    query,
  ]);

  const newsProps: ListProps = {
    list: catalogList?.map((catalog) => {
      return {
        ...catalog,
        description: striptags(catalog.description),
      } as Catalog;
    }),
    category: category,
    categoryFilter: categorySlug,
    onClick: onClickCard,
    onClickFavourite: () => {},
    onClickOnlineService: openLink,
    searchTextFilter,
    searchText: query,
    onClickSearch,
    onSetSearchValue: setSearchTextFilter,
    categoryList,
    onCategorySelect: setCategorySlug,
    categoryTitle: currentCategory?.name,
    departmentList,
    onDepartmentClick,
    orgaoSlug: orgao,
    orgaoTitle: currentDepartment?.shortName,
    disposition,
    onDispositionClick: setDisposition,
    onPaginate,
    resultTotal,
    currentPage,
    breadcrumbs,
  };

  return createElement(List, newsProps);
};
