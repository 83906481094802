import React, { FC } from "react";
import { Menu } from "antd";
import { Container } from "../../components/container/container";
//@ts-ignore
import { Link } from "react-scroll";

export interface LinkBarProps {
  linkList: { text: string; link: string }[];
}

export const LinkBar: FC<LinkBarProps> = ({ linkList }) => {
  return (
    <div className="xvia-link-bar" role="navigation" aria-label="Barra de links">
      <Container>
        <Menu
          mode="horizontal"
          items={linkList?.map((linkItem) => {
            return {
              key: linkItem.link,
              label: (
                <Link
                  activeClass="active"
                  className="search"
                  to={linkItem.link}
                  spy={true}
                  smooth={true}
                  duration={500}
                  aria-label={`Ir para ${linkItem.text}`}
                >
                  {linkItem.text}
                </Link>
              ),
            };
          })}
        />
      </Container>
    </div>
  );
};
