import React, { FC, useEffect } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { CatalogContainer } from "../modules/catalog/catalog.container";

export const Catalog: FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <>
      <CatalogContainer />
      <FooterContainer />
    </>
  );
};
