import React, { FC } from "react";
import { Container } from "../../components/container/container";
import {
  CategoryCard,
  CategoryCardProps,
} from "../../components/cards/category-card";
import { Col, Row } from "antd";
import { BreakPage } from "../../components/break-page/break-page";

export interface CategoryProps {
  categoryList: CategoryCardProps[];
}

export const Category: FC<CategoryProps> = ({ categoryList }) => {
  return (
    <div className="xvia-category">
      <Container className="xvia-category-services__container">
        <BreakPage text={"SERVIÇOS POR CATEGORIA"} color={"#B9FB12"} />
        <Row>
          {categoryList?.map((category) => {
            return (
              <Col>
                <CategoryCard
                  icon={category?.icon}
                  detail={category?.detail}
                  onClick={category.onClick}
                  key={category.detail}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};
